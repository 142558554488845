<template>
    <div class="tw-text-sm">
        <p
            class="tw-mb-1 tw-mt-4 tw-font-semibold"
            v-if="showNickName && !isService"
            :class="{
                'tw-text-right': message.owner,
            }"
        >
            {{ message.owner ? $t('interaction.messagingView.ownerLabel') : message.nickname }}
        </p>
        <div
            class="tw-flex tw-items-center tw-space-x-2"
            v-if="message.body"
            @click="showTime = !showTime"
            :class="{
                'tw-ml-auto tw-w-5/6 tw-justify-end': message.owner && !isService,
                'tw-mr-auto tw-w-5/6 tw-justify-start': !message.owner && !isService,
                'tw-mx-auto tw-justify-center': isService,
            }"
        >
            <div class="tw-text-xs" v-if="message.owner">
                <p v-if="!message.sendSuccess">
                    <lds-ellipsis class="tw-h-7 tw-w-9 tw-fill-secondary" />
                </p>
            </div>
            <div
                class="tw-px-4 tw-py-2"
                :class="{
                    'tw-rounded-l-xl tw-bg-blue-400 tw-text-white': message.owner && !isService,
                    'tw-rounded-r-xl tw-bg-stone-100 tw-text-black': !message.owner && !isService,
                    'tw-rounded-tr-xl': message.owner && firstMessage && !isService,
                    'tw-rounded-br-xl': message.owner && lastMessage && !isService,
                    'tw-rounded-tl-xl': !message.owner && firstMessage && !isService,
                    'tw-rounded-bl-xl': !message.owner && lastMessage && !isService,
                    'tw-text-center tw-text-xs tw-text-gray-500': isService,
                }"
            >
                <p v-if="isService && te(`interaction.messagingView.serviceMessage.${message.body}`)">
                    {{ t(`interaction.messagingView.serviceMessage.${message.body}`) }}
                </p>
                <div v-else class="chat-msg-wysiwyg" v-html="message.body"></div>
            </div>
        </div>
        <transition name="chat-time">
            <div
                class="tw-my-1 tw-flex tw-items-center tw-space-x-1 tw-text-xs tw-text-gray-400"
                v-if="showTime && message.addDate"
                :class="{
                    'tw-justify-end': message.owner,
                }"
            >
                <icon-clock class="tw-w-3" />
                <span>{{ postDate }}</span>
            </div>
        </transition>
    </div>
</template>
<script setup lang="ts">
    import IconClock from '@/components/icons/IconClock.vue';
    import LdsEllipsis from '@/components/LdsEllipsis.vue';
    import Dayjs from '@/dayjs';
    import { computed, onMounted, onUnmounted, ref, toRefs } from 'vue';
    import { useI18n } from 'vue-i18n';

    const postDate = ref();
    let intervalId: any;
    const showTime = ref(false);
    const { te, t } = useI18n();

    const props = defineProps<{
        message: ChatMessage;
        showNickName?: boolean;
        firstMessage?: boolean;
        lastMessage?: boolean;
    }>();
    const { message } = toRefs(props);
    const isService = computed(() => !message.value.nickname);

    const handle = () => {
        postDate.value = Dayjs(message.value.addDate).fromNow();
    };

    onMounted(() => {
        intervalId = setInterval(() => handle(), 5000);
        handle();
    });

    onUnmounted(() => {
        if (intervalId) clearInterval(intervalId);
    });
</script>

<style lang="scss">
    .chat-msg-wysiwyg {
        text-align: left;

        // Gestion des images
        img {
            max-width: 100%;
            height: auto;
            max-height: 200px;
            object-fit: contain;
            border-radius: 0.5rem;
        }

        // Gestion du texte
        p {
            margin: 0.5rem 0;
            line-height: 1.4;
            word-break: break-word;
        }

        // Gestion des listes
        ul, ol {
            margin: 0.5rem 0;
            padding-left: 1.5rem;
        }

        // Gestion des listes non ordonnées
        ul {
            list-style-type: disc;
        }

        // Gestion des listes ordonnées
        ol {
            list-style-type: decimal;
        }

        // Gestion des éléments de liste
        li {
            margin: 0.25rem 0;
            line-height: 1.4;
            word-break: break-word;

            // Gestion des listes imbriquées
            ul, ol {
                margin: 0.25rem 0;
            }
        }

        // Gestion des liens
        a {
            color: #3b82f6;
            text-decoration: underline;
            word-break: break-word;
        }

        // Gestion des titres
        h1, h2, h3, h4, h5, h6 {
            margin: 0.5rem 0;
            font-size: 1rem;
            line-height: 1.4;
        }

        // Gestion des citations
        blockquote {
            margin: 0.5rem 0;
            padding-left: 0.5rem;
            border-left: 3px solid #e5e7eb;
        }

        // Gestion des tableaux
        table {
            width: 100%;
            border-collapse: collapse;
            margin: 0.5rem 0;

            td, th {
                padding: 0.25rem;
                border: 1px solid #e5e7eb;
            }
        }

        // Gestion du code
        pre, code {
            background-color: #f3f4f6;
            padding: 0.25rem 0.5rem;
            border-radius: 0.25rem;
            font-size: 0.875rem;
            overflow-x: auto;
        }
    }
</style>
